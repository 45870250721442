



















import { Observer } from 'mobx-vue'
import { Component, Vue, Inject, Prop } from 'vue-property-decorator'
import SenderViewModel from '../viewmodels/sender'

@Observer
@Component({
  components: {}
})
export default class InfomationDialog extends Vue {
  @Inject() vm!: SenderViewModel
  @Prop() data!: any
}
